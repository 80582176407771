import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "particles.js/particles";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

// Stylesheets
import ("styles/application.css");

// JQuery
global.$ = require("jquery");
require("jquery");
require("jquery-ui");

// Copy To Cliboard
import copy from 'copy-to-clipboard'
window.copy = copy

import 'luxon';

// Stimulus
import "controllers";
require("../helpers/");

// ActionText
require("trix")
require("@rails/actiontext")