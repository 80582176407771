import { Controller } from "stimulus";
import Splide from '@splidejs/splide';

export default class extends Controller {
    static targets = ["splide"];
    static values = { isSearchSplide: Boolean, focus: Number };

    connect() {
        if (this.isSearchSplideValue) {
            new Splide(this.splideTarget, {
                perPage: 3,
                perMove: 1,
                type: 'loop',
                start: this.focusValue,
                gap: '12px',
                drag: 'free',
                snap: true,
                breakpoints: {
                    924: {
                        perPage: 3
                    },
                    724: {
                        perPage: 2
                    },
                },
            }).mount();
        } else {
            new Splide(this.splideTarget, {
                perPage: 1,
                perMove: 1,
                rewind: true,
            }).mount();
        }
    }
}
