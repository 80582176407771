window.toggleHomeService = function toggleHomeService(button) {
  let input = document.querySelector('input#add_ons');

  if (!!input) {
    let indexes = input.value;

    if (input.value === "") {
      input.value = "Home Service";
      button.innerHTML = '<i class="far fa-check-circle"></i> Added';
      window.createNotice(`Added: Home Service (Add-on)`);
    } else {
      if (input.value === "Home Service") {
        input.value = "";
        button.innerHTML = '<i class="far fa-plus"></i>';
        window.createNotice(`Removed: Home Service (Add-on)`);
      } else {
        input.value = "Home Service";
        button.innerHTML = '<i class="far fa-check"></i> Added';
        window.createNotice(`Added: Home Service(Add-on)`);
      }
    }
  }
}